import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BearHunt from "../components/BearHunt"
import Caterpillar from "../components/Caterpillar"
import GoodNight from "../components/GoodNight"
import Sari from "../components/Sari"

 
import {Spring} from 'react-spring/renderprops'
import VisibilitySensor from "react-visibility-sensor";


// import Hand from "../images/hand.svg";
import Hand from "../images/lamp-2.svg";


const darkGreen = '#274043';
const red ='#F0625D';
const yellow = '#F5AA28';


const Ideas = () => (
  <Layout>
    <SEO title="Ideas" />
    <Container className='yellow-b' fluid>


{/* Title */}

<Container className=''>
<div className='sections-top-gap'></div>

  <Row className='pt-5'>
    <Col xs={6} sm={6} md={7} lg={8} xl={8} className=''>

      <VisibilitySensor>
        {({ isVisible }) => (
                  <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                  {({ opacity  }) => 
              <div style={{ opacity }}>
                <h4 className='red'>
                  Story Ideas: 
                </h4>
                </div>
                  }
                  </Spring>
                  )}
        </VisibilitySensor>
      </Col>


      <Col xs={6} sm={6} md={5} lg={4} xl={4} className='float-right'>
        <Row>

        <VisibilitySensor>
        {({ isVisible }) => (
                  <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                  {({ opacity  }) => 
<div style={{ opacity  }}>

          <Col xs={12} className='pt-1 pb-4'>
            <Hand style={{minWidth: '20px', maxWidth: '30px', height: 'auto'}}/>
          </Col>
          <Col xs={12} className='book-covers'>
          <p className=''>Click or tap on book covers to get some ideas from popular children's books...</p>
          </Col>
          </div>
}
                  </Spring>
                  )}
        </VisibilitySensor>


        </Row>
      </Col>



    </Row>
</Container>


<Container className='mt-5 pt-5 pb-5'>
        <Row className='text-center'>
                   
        <Col xs={6} lg={3} className='pb-5'>    
          <BearHunt/>
          </Col>

          <Col xs={6} lg={3} className='pb-5'>    
          <Caterpillar/>
        
          </Col>

          <Col xs={6} lg={3} className='pb-5'>    
          < GoodNight/>
         </Col>

         <Col xs={6} lg={3} className='pb-5'>    
         <Sari/>
      </Col>
       
    </Row>
  </Container>


    </Container>
  </Layout>
)

export default Ideas







