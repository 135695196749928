import React from 'react';
import {Modal, Row, Col, Button, Container} from 'react-bootstrap';

import Book3 from "../images/book-3.svg";
import BookModal3 from "../images/book-modal-3.svg";

import BookIcon from "../images/book-icon.svg";

import Lamp from "../images/lamp.svg";

import BookArrow from "../images/book-arrow.svg";


class BearHunt extends React.Component {
  render() {
        return (
          <div>
          <Caterpillar />
          </div>
        );
    }
}

// ------------- Image + Button ---------------- //

function Caterpillar() {
  const [show, setShow] = React.useState(false);

  return (
    <>
    <div id='book-button' onClick={() => setShow(true)} style={{cursor: 'pointer'}}>

      <Book3 className='book-icon'/>
      <div className='mt-3 book-covers'>
       <h2>GOODNIGHT MOON</h2>
      <p className='red'>by Margaret Wise Brown</p>
        </div>

<div className='book-arrow'>
<BookArrow className='book-arrow-size'/>
</div>

    </div>

 {/* // ------------- MODAL ---------------- // */}
     
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"    
        centered    
      >

        <Modal.Header closeButton>
<p className='red'>Ideas from popular children's books:</p>
        </Modal.Header>

        <Modal.Body>

        <Container>

      <Row className='mt-0'>
        <Col xs={3} lg={4}xl={3} >
          <div className='text-left' style={{width:'100%' }}>
              <BookModal3 style={{ maxWidth:'200px'}}/>
          </div>
        </Col>

        <Col xs={9} lg={8} xl={9} className='books'>
          <h2 >"GOODNIGHT MOON"</h2>
          <p className='italics'><span>by: </span><a className='bold' href="https://www.w3schools.com" target="_blank">Margaret Wise Brown</a>
          {/* <BookIcon className='text-icons'/>&ensp; */}
          </p>           
        </Col>
      </Row>

      <Row className='books mt-3 mb-4'>
        <Col xs={12} md={{ span: 9, offset: 3}} lg={{ span: 8, offset: 4}} xl={{ span: 9, offset: 3 }}  className='books'>  
          <h1 className='bold red'><Lamp className='text-icons'/> &ensp; How to make it a sensory story:</h1>
        </Col>

        <Col xs={12} md={{ span: 9, offset: 3}} lg={{ span: 8, offset: 4}} xl={{ span: 9, offset: 3 }} >
          <ol className='mt-0 pt-0 mb-0 bold'>
            <li>Flashlight for the light of the moon</li>
            <li>Telephone</li>
            <li>Red Balloon</li>
            <li>Hairbrush</li>
            <li>Mittens</li>
            <li>Socks</li>
          </ol>
        </Col>
      </Row>
        {/* <Col xs={12} sm={12} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} className='mt-0 pt-0 mb-0'> */}
           
           </Container>

       </Modal.Body>

      </Modal>
    </>
  );
}

  

export default BearHunt;